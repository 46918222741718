.pagebutton {
  background-color: white;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  margin-bottom: 20px;
}
.butons {
  margin-left: -540px;
}
.btn_ovel {
  margin-left: 30px;
  background-color: white;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 17.2915px;
  font-weight: bold;
  font-size: 11.2903px;
  line-height: 14px;
  letter-spacing: -0.015em;
  color: #000000;
  height: 35px;
  width: 146px;
}

.btn_ovel:hover {
  color: white;
  background-color: black;
}
