.header {
  background-color: white;
  box-shadow: 5px 5px 10px #e5e5e5;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  padding-inline: 1em;
  padding-block: 1em;
}

.header_image {
  height: 55px;
  width: 131px;
}

.header_text {
  font-weight: bold;
  font-size: 32.962px;
  line-height: 40px;
  color: #333333;
}

.header-right {
  margin-left: auto;
  display: flex;
  gap: 4px;
}

.header-right__subscription-count {
  background-color: #333333;
  border-radius: 999px;
  font-size: 1rem;
  color: white;
  padding: 0.5em;
  margin-bottom: unset;
}

.header-right__send-summary {
  border-radius: 999px !important;
}
