.right_slots {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 75vh;
  flex: 0.48;
  margin-left: 110px;
}
.colSide {
  display: flex;
  flex-direction: row;
}
.slot_left {
  display: flex;
  flex-direction: column;
  width: 260px;
}
.slot_right {
  display: flex;
  flex-direction: column;
}
.slots_col1_p {
  display: flex;
  flex-direction: column;
}
.page_text {
  font-weight: normal;
  font-size: 17.5461px;
  line-height: 26px;
  text-align: left;
  letter-spacing: -0.015em;
  color: #000000;
  width: 250.76px;
  margin-bottom: 20px;
}
.slot_bottom {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.slots_col3_p,
.slots_col3_wp {
  display: flex;
  flex-direction: column;
}
.topics {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 92%;
}
.span1 {
  font-weight: 300;
  font-size: 5.48315px;
  line-height: 7px;
  display: flex;
  align-items: center;
  letter-spacing: -0.015em;
  color: #000000;
}
.span2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 3.28989px;
  line-height: 4px;
  display: flex;
  align-items: center;
  letter-spacing: -0.015em;
  color: #000000;
}

.topics_r {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 85%;
}
