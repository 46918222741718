.navbarE {
  background-color: white;
  display: flex;
  flex: 0.2;
  flex-direction: column;
  align-items: center;
  margin-left: 40px;
  margin-top: 8px;
}
.navE {
  display: flex;
  flex-direction: column;
  width: 219px;
  height: auto;
  background: #e9e9e9;
  border-radius: 16px;
}
.navE_items {
  margin-top: 10px;
}
.navE_items > .span {
  text-decoration: none !important;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: black;
  cursor: pointer;
  padding: 15px 0px 0 35px;
}

.navE_items > .span:hover {
  color: #515151;
}

.navE_items > .span > .btn_add {
  color: white;
  background-color: black;
  width: 62px;
  height: 28px;
  border-radius: 11px;
  border: none;
  margin-bottom: 20px;
}
.add__modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  margin-top: 30px;
}
