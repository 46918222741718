.heading_slots {
  display: flex;
  flex-direction: column;
  margin-left: 0px;
}
.slotItem {
  display: flex;
  flex-direction: column;
}
.slot_items > span {
  margin-right: 20px;
  margin-left: 40px;
}
.topic {
  width: auto;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.015em;
  color: #000000;
}
.slot_items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.spans {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 5px 0px 5px 0px;
}
.dropdown-basic {
  width: 168px;
  height: 27px;
  left: 841px;
  top: 323px;
  background: #e0e0e0;
  border-radius: 3px;
  border: none;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  color: #a3a3a3;
  text-align: center;
}
