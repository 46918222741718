.card3 {
  width: 241.99px;
  height: 80.42px;
  background: #ffffff;
  border: 0.365543px solid rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 18px;
  flex: 1;
  border: none;
}
.card3_box {
  z-index: 1;
  width: 106.01px;
  height: 80.42px;
  display: flex;
  flex-direction: column;
  background: #e5e5e5;
  background: linear-gradient(
      to top left,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0.05) calc(50% - 0.8px),
      rgba(0, 0, 0, 0.3) 50%,
      rgba(0, 0, 0, 0.05) calc(50% + 0.8px),
      rgba(0, 0, 0, 0.05) 100%
    ),
    linear-gradient(
      to top right,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0.05) calc(50% - 0.8px),
      rgba(0, 0, 0, 0.3) 50%,
      rgba(0, 0, 0, 0.05) calc(50% + 0.8px),
      rgba(0, 0, 0, 0.05) 100%
    );
}
.innerBox2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  width: 106.01px;
  height: 80.42px;
  border: none;
  position: absolute;
  background: linear-gradient(
      to top left,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0.05) calc(50% - 0.8px),
      rgba(0, 0, 0, 0.3) 50%,
      rgba(0, 0, 0, 0.05) calc(50% + 0.8px),
      rgba(0, 0, 0, 0.05) 100%
    ),
    linear-gradient(
      to top right,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0.05) calc(50% - 0.8px),
      rgba(0, 0, 0, 0.3) 50%,
      rgba(0, 0, 0, 0.05) calc(50% + 0.8px),
      rgba(0, 0, 0, 0.05) 100%
    );
}
.card3_img {
  z-index: -1;
  box-sizing: border-box;
  width: 106.01px;
  height: 80.42px;
  position: absolute;
  border: none;
  font-weight: 300;
  font-size: 5.48315px;
  line-height: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.015em;
  text-align: center;
  color: #000000;
}
.card3_body {
  display: flex;
  flex: 0.8;
  flex-direction: column;
  padding-top: 0;
  padding-right: 0px;
  padding-left: 5px;
}
.card3_title {
  font-weight: bold;
  font-size: 8.77303px;
  line-height: 13px;
  letter-spacing: -0.015em;
  color: #000000;
}
.card3_text {
  font-style: normal;
  font-weight: normal;
  font-size: 4.38652px;
  line-height: 11px;
  letter-spacing: -0.015em;
  color: rgba(0, 0, 0, 0.8);
}
.card3_btn_slot {
  z-index: 2;
  border: none;
  background: #151617;
  border-radius: 17.2915px;
  width: 97px;
  height: 23px;
  font-weight: bold;
  font-size: 11.2903px;
  line-height: 14px;
  letter-spacing: -0.015em;
  color: #ffffff;
  position: absolute;
  margin-top: 28px;
  margin-left: 60px;
}
.card3_btn_slot:hover {
  background: #151617;
}
