.edit_article {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 75vh;
  flex: 1;
}

.edit_article_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 0.8;
  background-color: white;
  padding: 30px 0 0 5px;
}
.edit_container_form {
  width: 95%;
}
.edit_form_table {
  background: #ececec;
  border-radius: 16px;
}
.tbl {
  width: 150%;
  height: 200px;
  overflow-y: auto;
  background: #ececec;
  border-radius: 16px;
}
.tbl_header {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  color: #000000;
}
.table > :not(caption) > * > * {
  border-bottom: none;
}
.edit_form_row {
  margin-bottom: 20px;
}
.edit_form_label {
  display: flex;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  color: #000000;
}

.edit_form_control {
  width: 141px;
  height: 35px;
  background: #e5e5e5;
  border-radius: 5px;
  font-size: 14px;
  padding-left: 10px;
  border: none;
  margin-left: -40px;
}

.edit_form_control_tittle {
  width: 466px;
  height: 87px;
  background: #e5e5e5;
  border-radius: 5px;
  font-size: 14px;
  border: none;
  margin-left: -40px;
  padding-left: 10px;
}
.edit_form_img_row {
  margin-left: -42px;
  display: flex;
}
.edit_form_control_image {
  display: flex;
  width: 315px;
  justify-content: center;
  align-items: center;
  height: 192px;
  background: #e5e5e5;
  border-radius: 5px;
  border: none;
  margin-right: 52px;
  cursor: pointer;
}
.edit_form_control_imaget {
  display: flex;
  width: 200px;
  justify-content: center;
  align-items: center;
  height: 120px;
  background: #e5e5e5;
  border-radius: 5px;
  border: none;
  margin-right: 52px;
  cursor: pointer;
}
.edit_form_control_image__uploaded {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 315px;
  height: 192px;
  background: #e5e5e5;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  object-fit: cover;
}
.edit_form_control_image__uploadedt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 120px;
  background: #e5e5e5;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  object-fit: cover;
}
.edit_container_form_control_image {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 315px;
  height: 192px;
  object-fit: cover;
  background: #e5e5e5;
  border-radius: 5.17153px;
  border: none;
  cursor: pointer;
}
.edit_form_control_imageSlot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 315px;
  height: 192px;
  background: #e5e5e5;
  border-radius: 5px;
  border: none;
  margin-right: 52px;
  cursor: pointer;
  margin-bottom: 20px;
}
.edit_form_control_imageSlot__uploaded {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 315px;
  height: 192px;
  object-fit: cover;
  background: #e5e5e5;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}
.edit_form_control_image > input,
.edit_form_control_imageSlot > input {
  visibility: hidden;
  width: 0;
  height: 0;
  cursor: pointer;
}
.edit_form_control_image > i,
.edit_form_control_imageSlot > i {
  color: #9f9f9f;
  font-size: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  cursor: pointer;
  z-index: 1;
}
.edit_article_slots {
  display: flex;
  flex-direction: column;
  width: 800px;
  height: 1350px;
  overflow: auto;
  border: 1px solid #454545;
  border-radius: 5px;
  margin-left: -40px;
  padding: 15px;
}

.edit_form_control_atl {
  width: 750px;
  height: 283px;
  background: #e5e5e5;
  border-radius: 5px;
  border: none;
  margin-bottom: 20px;
}
.btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.btn_edit {
  background: #ea7000;
  border-radius: 17.2915px;
  height: 35px;
  width: 146.77px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  color: #ffffff;
  border: none;
}

.btn_delete {
  background: #ea0000;
  border-radius: 17.2915px;
  height: 35px;
  width: 146.77px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  color: #ffffff;
  border: none;
  margin-left: 50px;
  margin-right: -120px;
}
.selectTheRow:hover {
  cursor: pointer;
  background-color: #b4b4b4;
}
