.left_solts {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 75vh;
  flex: 0.5;
}
.left_head {
  width: 600px;
  height: 38px;
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  color: #000000;
  margin-bottom: 20px;
}
.left_head > span {
  margin-right: 70px;
  margin-left: 60px;
}
.left_head > .span3 {
  margin-left: 110px;
}
.heading_slots {
  display: flex;
  text-align: center;
  width: 580px;
  height: auto;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 16px;
  margin-bottom: 20px;
}
.legend {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  letter-spacing: -0.015em;
  color: #000000;
  width: 150px;
  display: flex;
  justify-content: center;
  margin-top: -10px;
  margin-left: 30px;
  background-color: white;
}
.slot {
  display: flex;
  flex-direction: column;
}
.btns_cs {
  margin-left: auto;
  margin-right: 55px;
  margin-top: 10px;
  font-size: 10px;
}
.btns_cs > .save {
  background: #151617;
  color: white;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 7px;
  height: 15px;
  width: 50px;
  margin-left: 15px;
  line-height: 2px;
}
.btns_cs > .cancel {
  background-color: white;
  color: black;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 7px;
  height: 15px;
  width: 50px;
  line-height: 2px;
}
