.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
}
.login-container {
  width: 500px;
  height: 610px;
  background-color: #1d2120;
  box-shadow: 6px 7px 18px rgba(0, 0, 0, 0.25);
  border-radius: 31px;
  margin-top: 25px;
  flex-direction: column;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logo-image {
  width: 242px;
  height: 100px;
  margin-top: 50px;
}
.login-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  margin-top: 90px;
  margin-bottom: 50px;
}
.login-form {
  text-align: left;
  display: flex;
  flex-direction: column;
}
.login-label {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  margin-bottom: 15px;
}
.login-input {
  padding-left: 5px;
  padding-bottom: 5px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(176, 176, 176, 0.24);
  margin-bottom: 15px;
  white-space: nowrap;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid white;
  background-color: #1d2120;
}
.login-input:hover {
  border-top: none;
  border-left: none;
  border-right: none;
}
.login-input:focus {
  outline: none;
}
.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}
.login-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 92px;
  height: 34px;
  background: #ffffff;
  border-radius: 6px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  color: #1d2120;
  background-color: none;
  align-items: center;
  text-decoration: none;
}
