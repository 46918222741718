.slotcard2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.card2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 195px;
  height: 78.23px;
  border: none;
  margin: 0px 30px 20px 0px;
}
.btn_slot2 {
  background: #151617;
  border-radius: 17.2915px;
  height: 23px;
  width: 97px;
  border: none;
  font-weight: bold;
  font-size: 11.2903px;
  line-height: 14px;
  letter-spacing: -0.015em;
  color: #ffffff;
  z-index: 1;
  position: absolute;
  margin-top: -2px;
}

.btn_slot2:hover {
  background: #151617;
}
.body2 {
  padding-left: 0px;
}
.body2 > .title2 {
  font-weight: bold;
  font-size: 8.77303px;
  line-height: 13px;
  letter-spacing: -0.015em;
  color: black;
  margin-top: 0px;
}

.body2 > .text2 {
  font-weight: normal;
  font-size: 5.48315px;
  line-height: 11px;
  letter-spacing: -0.015em;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 0px;
}

.btn_learn_more {
  background: white;
  border: 0.365543px solid #151617;
  box-sizing: border-box;
  border-radius: 2.19326px;
  font-weight: 500;
  font-size: 5.48315px;
  line-height: 7px;
  letter-spacing: -0.015em;
  color: #151617;
  height: 14.62px;
  width: 47.52px;
}
