.card4 {
  width: 241.99px;
  height: 80.42px;
  background: #ffffff;
  border: 0.365543px solid rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 10px;
  flex: 1;
  border: none;
}
.card4_body {
  display: flex;
  flex: 0.8;
  flex-direction: column;
  padding-top: 0;
  padding-right: 0px;
  padding-left: 5px;
}
.card4_title {
  font-weight: bold;
  font-size: 8.77303px;
  line-height: 13px;
  letter-spacing: -0.015em;
  color: #000000;
}
.card4_text {
  font-size: 6.57978px;
  line-height: 11px;
  letter-spacing: -0.015em;
  color: rgba(0, 0, 0, 0.8);
}
.card4_btn_slot {
  border: none;
  background: #151617;
  border-radius: 17.2915px;
  width: 97px;
  height: 23px;
  font-weight: bold;
  font-size: 11.2903px;
  line-height: 14px;
  letter-spacing: -0.015em;
  color: #ffffff;
  z-index: 1;
  position: absolute;
  margin-top: 28px;
  margin-left: 60px;
}
.card4_btn_slot:hover {
  background: #151617;
}
.learn_more4 {
  background: #151617;
  border-radius: 2.19326px;
  width: 36.39px;
  height: 11.2px;
  font-weight: 500;
  font-size: 3.65543px;
  line-height: 4px;
  margin-top: -10px;
  letter-spacing: -0.015em;
  box-sizing: border-box;
  border: none;
  color: #ffffff;
  padding-left: 2px;
  padding-right: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.learn_more4:hover {
  background: #151617;
}
