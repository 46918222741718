.new_article {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 75vh;
  flex: 1;
}

.new_article_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 0.8;
  background-color: white;
  padding: 30px 0 0 5px;
}
.container_form {
  width: 95%;
}
.form_row {
  margin-bottom: 20px;
}
.form_label {
  display: flex;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  color: #000000;
}
.form_control {
  width: 141px;
  height: 35px;
  background: #e5e5e5;
  border-radius: 5px;
  font-size: 14px;
  padding-left: 10px;
  border: none;
  margin-left: -40px;
}

.form_control_tittle {
  padding-left: 10px;
  width: 466px;
  height: 87px;
  background: #e5e5e5;
  border-radius: 5px;
  font-size: 14px;
  border: none;
  margin-left: -40px;
}
.form_img_row {
  margin-left: -42px;
  display: flex;
}
.form_control_image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 315px;
  height: 192px;
  background: #e5e5e5;
  border-radius: 5px;
  border: none;
  margin-right: 52px;
  cursor: pointer;
}
.form_control_image__uploaded1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 315px;
  height: 192px;
  background: #e5e5e5;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  object-fit: cover;
}
.form_control_imageSlot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 315px;
  height: 192px;
  background: #e5e5e5;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-bottom: 20px;
}
.form_control_imageSlot__uploaded {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 315px;
  height: 192px;
  background: #e5e5e5;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  object-fit: cover;
}
.form_control_image > input,
.form_control_imageSlot > input {
  visibility: hidden;
  width: 0;
  height: 0;
  cursor: pointer;
}
.form_control_image > i,
.form_control_imageSlot > i {
  color: #9f9f9f;
  font-size: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  margin-top: 25px;
  cursor: pointer;
}
.article_slots {
  display: flex;
  flex-direction: column;
  width: 800px;
  height: 1350px;
  overflow: auto;
  border: 1px solid #454545;
  border-radius: 5px;
  margin-left: -40px;
  padding: 15px;
}
.form_control_atl {
  width: 750px;
  min-height: 283px;
  margin-bottom: 20px;
  background: #e5e5e5;
  border-radius: 5px;
  border: none;
  padding: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  height: fit-content;
  color: #454545;
}
.form_control_FullArticle {
  width: 750px;
  min-height: 283px;
  margin-bottom: 20px;
  background: #e5e5e5;
  border-radius: 5px;
  border: none;
  padding: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;

  color: #454545;
}
.btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.btn_save {
  background: #076b04;
  border-radius: 17.2915px;
  height: 35px;
  width: 146.77px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  color: #ffffff;
}

.form__btn_clear {
  background: #ea0000;
  border-radius: 17.2915px;
  height: 35px;
  width: 146.77px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  color: #ffffff;
  margin-left: 50px;
  margin-right: -120px;
}
.findUrl {
  width: 340px;
  height: 30px;
  margin-top: 50px;
}
.editorParent {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 81%;

  z-index: 1000;
  justify-content: center;
  /* height: fit-content; */
  background: #dddada;
  /* max-width: 1000px; */
  padding: 20px;
  max-height: 900px;
}
.editorHeader {
  background-color: white;
  width: 80%;
  height: auto;
  min-height: 400px;
  max-height: 800px;

  overflow-y: scroll;
}
.closeArea {
  display: flex;
  width: 75%;
  justify-content: flex-end;
}
.selectingImageParent {
  display: flex;
  flex-direction: row;
}
.form_control_imagee {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 120px;
  background: #e5e5e5;
  border-radius: 5px;
  border: none;
  margin-right: 52px;
  cursor: pointer;
}
.form_control_image__uploaded1e {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 120px;
  background: #e5e5e5;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  object-fit: cover;
}
