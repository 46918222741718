.add__category {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 350px;
  height: 200px;
  background: #ffffff;
  box-shadow: 7px 4px 14px -2px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(34px);
  border-radius: 30px;
}
.modal__close {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-self: end;
  width: auto;
  padding-right: 20px;
  padding-top: 10px;
  color: #2c261f;
  font-size: 28px;
}
.modalCategory {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.confirm__text {
  padding-bottom: 15px;
  text-decoration: none !important;
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: black;
  cursor: pointer;
}
.categoryInput {
  width: 250px;
  height: 35px;
  background: #e5e5e5;
  border-radius: 5px;
  font-size: 14px;
  border: none;
  padding-left: 10px;
}
.confirm__btns {
  padding: 15px;
}
.yes {
  color: white;
  background-color: black;
  width: 62px;
  height: 28px;
  border-radius: 8px;
  border: none;
  font-size: 14px;
}
