.users {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95vw;
  height: auto;
}
.users__Container {
  width: 1116px;
  height: 580px;
  overflow: auto;
  background: #e9e9e9;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  align-items: center;
  padding: 30px;
  overflow: auto;
}
.users__ContainerTable {
  width: 100%;
  height: auto;
}

.users__ContainerTable > th {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  color: #000000;
}
.users__ContainerTable > td {
  font-family: "Montserrat";
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  color: #000000;
}
.sendEmail {
  background: #707070;
  outline: none;
  border-radius: 6px;
  color: white;
  padding: 10px;
}
