.card {
  width: 241.99px;
  height: 263.92px;
  background: #ffffff;
  border: 0.365543px solid rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 10px;
}
.box {
  z-index: 1;
  width: 241.99px;
  height: 146.22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
      to top left,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0.05) calc(50% - 0.8px),
      rgba(0, 0, 0, 0.3) 50%,
      rgba(0, 0, 0, 0.05) calc(50% + 0.8px),
      rgba(0, 0, 0, 0.05) 100%
    ),
    linear-gradient(
      to top right,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0.05) calc(50% - 0.8px),
      rgba(0, 0, 0, 0.3) 50%,
      rgba(0, 0, 0, 0.05) calc(50% + 0.8px),
      rgba(0, 0, 0, 0.05) 100%
    );
}

.card-img {
  z-index: -1;
  box-sizing: border-box;
  width: 100%;
  height: 146.22px;
  border: none;
  position: absolute;
}
.innerBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  width: 100%;
  height: 113px;
  border: none;
  position: absolute;
  background: linear-gradient(
      to top left,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0.05) calc(50% - 0.8px),
      rgba(0, 0, 0, 0.3) 50%,
      rgba(0, 0, 0, 0.05) calc(50% + 0.8px),
      rgba(0, 0, 0, 0.05) 100%
    ),
    linear-gradient(
      to top right,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0.05) calc(50% - 0.8px),
      rgba(0, 0, 0, 0.3) 50%,
      rgba(0, 0, 0, 0.05) calc(50% + 0.8px),
      rgba(0, 0, 0, 0.05) 100%
    );
}
.card-body {
  flex: 0.44597;
  text-align: left;
}
.card-title {
  font-weight: bold;
  font-size: 8.77303px;
  line-height: 13px;
  letter-spacing: -0.015em;
  color: #000000;
}
.card-text {
  font-weight: normal;
  font-size: 6.57978px;
  line-height: 11px;
  letter-spacing: -0.015em;
  color: rgba(0, 0, 0, 0.8);
}
.btn_slot {
  border: none;
  background: #151617;
  border-radius: 17.2915px;
  width: 97px;
  height: 23px;
  font-weight: bold;
  font-size: 11.2903px;
  line-height: 14px;
  letter-spacing: -0.015em;
  color: #ffffff;
}
.btn_slot:hover {
  background: #151617;
}
