.article {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 75vh;
  width: 87vw;
  flex: 1;
}
.article_left {
  display: flex;
  flex-direction: column;
}
.article_heading {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: #515151;
  display: flex;
}
.nav_items > .span > .btn_add {
  display: block;
}
.article_left_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  padding-left: 35px;
}
.btn_dlt {
  border: none;
  background: #ff2c2c;
  border-radius: 8.1289px;
  height: 17px;
  width: 44px;
  font-weight: bold;
  font-size: 7px;
  line-height: 9px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #ffffff;
  margin-left: 25px;
}
