.navbar {
  background-color: white;
  display: flex;
  flex: 0.2;
  flex-direction: column;
  align-items: center;
  margin-left: 40px;
}
.nav {
  display: flex;
  flex-direction: column;
  width: 219px;
  height: auto;
  background: #e9e9e9;
  border-radius: 16px;
}
.nav_items {
  margin-top: 10px;
  margin-bottom: 30px;
}
.nav_items > .span {
  text-decoration: none !important;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: black;
  cursor: pointer;
  padding: 15px 0px 0 35px;
}

.nav_items > .span:hover {
  color: #515151;
}
