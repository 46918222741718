.na {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 75vh;
  width: 87vw;
  flex: 1;
}
.d_left {
  display: flex;
  flex-direction: column;
}
.na_heading {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: #515151;
  margin-top: 30px;
  display: flex;
  padding-left: 35px;
}
.nav_items > .span > .btn_add {
  display: none;
}
